import React from 'react';
import SectionIntro from '../../components/SectionIntro';
import Section from '../../components/Section';
import {Headline1} from '../../public/bundle';

const HeaderSection = () => (
    <Section Elm="header" title="Introduction">
        <div className="u-mar-vt-40 u-mar-vt-80@60">
            <SectionIntro>
                <SectionIntro.Image src="/images/header.svg" width={521} height={384} alt="" />
                <Headline1 className="u-mar-vt-40">Your Therapy’s Journey</Headline1>
                <p className="b-body b-body--large">
                    Thank you for telling us about your therapy. Based on your responses, we’ve outlined a journey to help get you started on your path to success.
                </p>
            </SectionIntro>
        </div>
    </Section>
)

export default HeaderSection;