import React from 'react';
import SectionIntro from '../../components/SectionIntro';
import {Headline1} from '../../public/bundle';

const ThankYouSection = () => (
    <header className="u-mar-vt-40 u-mar-vt-80@60">
        <SectionIntro>
            <SectionIntro.Image src="/images/header.svg" width={521} height={384} alt="" />
            <Headline1 className="u-mar-vt-40">Your Therapy’s Journey</Headline1>
            <p className="b-body b-body--large">
                Thank you for telling us about your therapy. Your therapy’s commercialization and post-launch journey awaits.
            </p>
        </SectionIntro>
    </header>
)

export default ThankYouSection;