import React, {useEffect} from 'react';
import styled from '@emotion/styled';
import SectionIntro from '../../components/SectionIntro';
import CircleIcon from '../../components/CircleIcon';
import HighlightEm from '../../components/HighlightEm';
import IconList from '../../components/IconList';
import PaddedBox from '../../components/PaddedBox';
import Section from '../../components/Section';
import { PATIENT_SURVEY_2020 } from '../../data/sources';
import {Eyebrow, Headline2, Headline3, Headline4, Footnotes} from '../../public/bundle';

const GraphGrid = styled.div`
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
    gap: ${40/16}rem;
    max-width: ${672/16}rem;
    margin: 0 auto;
    align-items: center;
    @media (min-width: 40em) {
        gap: ${80/16}rem;
        grid-template-columns: 1fr ${184/16}rem;
        grid-template-rows: auto;
    }
    `;

const GraphGridStat = styled.div`
    grid-column: 1;
    grid-row: 2;
    text-align: center;
    @media (min-width: 40em) {
        text-align: left;
        grid-row: 1;
    }
`;

const GraphGridImage= styled.img`
    grid-column: 1;
    grid-row: 1;
    justify-self: center;
    @media (min-width: 40em) {
        grid-column: 2;
    }
    `;

const CopayGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    gap: ${40/16}rem; 
    ul {
        list-style-type: square;
    }
    @media (min-width: 40em) {
        gap: ${60/16}rem; 
        grid-template-columns: .74fr 1fr;
        grid-template-rows: auto;
    }
`;

const CopayGridCell = styled.div`
    grid-column: 1;
    &:nth-child(1) {
        grid-row: 1
    }
    &:nth-child(2) {
        grid-row: 2;
    }
    @media (min-width: 40em) {
        &:nth-child(1) {
            grid-row:1;
            grid-column: 1;
        }
        &:nth-child(2) {
            grid-row:1;
            grid-column: 2;
        }
    }
`;

const AffordabilitySection = ({addFootnote}) => {
    useEffect(() => {
        addFootnote(PATIENT_SURVEY_2020);
    }, []);
    return (
        <Section title="Affordability Challenges">
            <div className="u-mar-vt-80 u-mar-vt-160@60">
                <SectionIntro>
                    <SectionIntro.Image src="/images/price-transparency.svg" width={460} height={430} alt="" />
                    <Headline2 className="u-mar-vt-40">Reduce Affordability Challenges for Your Patients</Headline2>
                    <p className="b-body b-body--large">
                        Your patients may face many challenges to affording your therapy — from the rise in high-deductible health plans and accumulator plans to job loss and lack of health insurance. Helping them through these challenges will help you maximize your therapy reach and promote brand loyalty. For that, you need a comprehensive and effective affordability strategy. 
                    </p>
                </SectionIntro>
                <div className="u-mar-vt-40 u-mar-vt-80@60">
                    <GraphGrid>
                        <GraphGridStat>
                            <Headline4 Elm="div">
                                <HighlightEm>36%</HighlightEm>{" "}of patients have given up their medications to help pay for life’s essentials.<Footnotes.Reference {...PATIENT_SURVEY_2020} />
                            </Headline4>
                        </GraphGridStat>
                        <GraphGridImage src="/images/36.svg" alt=""/>
                    </GraphGrid>
                </div>

                <div className="o-article-content o-article-content--full">
                    <div className=" u-mar-vt-40 u-mar-vt-80@60">
                        <Eyebrow className="u-color-blue">Affordability</Eyebrow>
                        <Headline3 serif className="u-mar-bottom-40">Copay solutions should support all patient populations</Headline3>
                        <CopayGrid>
                            <CopayGridCell>
                                <p className="b-body u-mar-top-0">
                                    Smarter, more strategic copay solutions are leveraging data and new machine-learning tools to connect eligible patients struggling under rising costs and affordability barriers with biopharma-sponsored programs that can help them get the medication they need. Flexible program design options allow you to develop an affordability strategy that meets the needs of different patient populations.  
                                </p>
                            </CopayGridCell>
                            <CopayGridCell>
                                <p className="b-body b-body--large u-mar-top-0">
                                    Copay strategies to consider:
                                </p>
                                <ul className="b-body u-pad-left-40">
                                    <li className="u-mar-vt-20 u-color-magenta">
                                        <span className="u-color-black">Patient enrollment</span>
                                    </li>
                                    <li className="u-mar-vt-20 u-color-magenta">
                                        <span className="u-color-black">Automatic coupon application at the pharmacy</span>
                                    </li>
                                    <li className="u-mar-vt-20 u-color-magenta">
                                        <span className="u-color-black">Tailored messaging for discounts through the pharmacy workflow</span>
                                    </li>
                                    <li className="u-mar-vt-20 u-color-magenta">
                                        <span className="u-color-black">PA denial support</span>
                                    </li>
                                </ul>
                            </CopayGridCell>
                        </CopayGrid>
                    </div>
                    <div className=" u-mar-vt-40 u-mar-vt-80@60">
                        <Headline4>How pharmacy operations can support affordability</Headline4>
                        <p className="b-body b-body--large u-mar-top-0 u-mar-bottom-80">
                            Our fully licensed in-house pharmacies:
                        </p>
                        <IconList>
                            <IconList.Item>
                                <CircleIcon src="https://images.ctfassets.net/2in405srp47m/2RIIltEjO6uk1di1bNLWXU/942248a9233efac8739c0d2d909af56a/opportunity.svg" />
                                <p className="b-body">
                                    <strong>Create</strong>{" "}logistical efficiencies.
                                </p>
                            </IconList.Item>
                            <IconList.Item>
                                <CircleIcon src="https://images.ctfassets.net/2in405srp47m/2WrwLzWlKm7uHJtIpPQJLx/8a44614249777dd8017563093c7368fa/patient_outreach.svg" />
                                <p className="b-body">
                                    <strong>Support</strong>{" "}your free goods and patient assistance programs in running more smoothly.
                                </p>
                            </IconList.Item>
                            <IconList.Item>
                                <CircleIcon src="https://images.ctfassets.net/2in405srp47m/6rhvci5hrnCgRjYyrs6O57/16e3880b32cd52879bf3617748c52d49/customization.svg" />
                                <p className="b-body">
                                    <strong>Offer</strong>{" "}tools to meet program goals from brand launches to niche specialty programs. 
                                </p>
                            </IconList.Item>
                            <IconList.Item>
                                <CircleIcon src="https://images.ctfassets.net/2in405srp47m/7q5R5khXyhI4Y87OTf9aiP/97cc0eb1226703208399884226cd7ac0/workflow.svg" />
                                <p className="b-body">
                                    <strong>Optimize</strong>{" "}your program with integrated data, analytics and strategic account management.
                                </p>
                            </IconList.Item>
                        </IconList>
                    </div>
                    <div className=" u-mar-vt-40 u-mar-vt-80@60">
                        <div className="u-bgc-orange-tint">
                            <PaddedBox>
                                <Eyebrow className="u-fw-light u-mar-bottom-40">Network Impact</Eyebrow>
                                <Headline2 Elm="p" serif className="u-mar-vt-0">
                                    <HighlightEm>50,000</HighlightEm>{" "}pharmacies in our network help you maximize patient reach.
                                </Headline2>
                            </PaddedBox>
                        </div>
                    </div>
                </div>

            </div>
        </Section>
    )
}

export default AffordabilitySection;