import react from 'react';
import styled from '@emotion/styled';

const SectionIntroContainer = styled.div`
    text-align: center;
    max-width: ${860/16}rem;
    margin: 0 auto;
`;

const SectionIntroImage = styled.img`
    max-width: ${props => props.width/16}rem;
    max-height: ${props => props.height/16}rem;
    width: 100%;
    height: auto;
`;

const SectionIntro = ({children}) => {
    return (

        <SectionIntroContainer>
            {children}
        </SectionIntroContainer>
    )
}

SectionIntro.Image = SectionIntroImage;

export default SectionIntro;