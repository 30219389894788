import React, {useEffect, useRef, useState, useContext} from 'react';
import { SectionNavContext } from './SectionNav';


const debounce = (callback, wait) => {
    let timeout = null
    return (...args) => {
      const next = () => callback(...args)
      clearTimeout(timeout)
      timeout = setTimeout(next, wait)
    }
};

const slugify = str => str.toLowerCase().trim().replace(/[^\w\s-]/g, '').replace(/[\s_-]+/g, '-').replace(/^-+|-+$/g, '');

const Section = ({title, Elm="section", children}) => {

    const sectionElm = useRef();
    const [isInView, setIsInView] = useState(false);
    const {addSection, showSectionNavList} = useContext(SectionNavContext);
    const sectionId = slugify(title);

    const scrollListener = () => {

        const section = sectionElm.current;

        if (!section) {
            return;
        }

        const sectionPos = section.getBoundingClientRect();
        setIsInView((sectionPos.top < window.innerHeight) / 2 && (sectionPos.top + sectionPos.height > 0)); 

    }

    const handleScroll = debounce(scrollListener, 1);

    useEffect(() => {
        if (showSectionNavList) {
            window.addEventListener('scroll', handleScroll);
            return () => window.removeEventListener('scroll', handleScroll);
        }
    }, [isInView]);
    
    useEffect(() => {
        if (showSectionNavList) {
            addSection({sectionId, title, isInView});
        }
    }, [isInView]);

    return (
        <Elm ref={sectionElm}>
            <div id={sectionId} style={{transform: 'translateY(-70px)'}} />
            {children}
        </Elm>
    );

}

export default Section;