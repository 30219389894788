import React from 'react';
import styled from '@emotion/styled';

const IconWrap = styled.div`
    width:${122/16}rem;
    height:${122/16}rem;
    display: flex;
    align-self: center;
    align-items: center;
    justify-items: center;
    background: #ffffff;
    border-radius: 100%;
    box-shadow: inset 0 0 0 ${5/16}rem #E6F3FB;
`;

const Icon = styled.img`
    width: 50%;
    height: 50%;
    object-fit: contain;
    margin: auto;
`;

const CircleIcon = ({alt="", ...rProps}) => (
    <IconWrap>
        <Icon alt={alt} {...rProps} />
    </IconWrap>
);

export default CircleIcon;