import React from 'react';
import styled from '@emotion/styled';
import SectionIntro from '../../components/SectionIntro';
import CircleIcon from '../../components/CircleIcon';
import Image from 'next/image';
import IconList from '../../components/IconList';
import Section from '../../components/Section';
import {Eyebrow, Headline2, Headline3, Headline4, Accordion} from '../../public/bundle';


const EndToEndGrid = styled.div`
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    gap: ${100/16}rem;
    align-items: flex-start;
    display: grid;
    @media (min-width: 60em) {
        grid-template-columns: .89fr 1fr;
        grid-template-rows: auto;
    }
`;

const EndToEndGridImgWrap = styled.div`
    justify-self: center;
    grid-column: 1;
    grid-row: 1;
    @media (min-width: 60em) {
        justify-self: flex-start;
        grid-column: 1;
    }
    `

const EndToEndGridContent = styled.div`
    grid-column: 1;
    grid-row: 2;
    @media (min-width: 60em) {
        align-self: flex-start;
        grid-column: 2;
        grid-row: 1;
    }
`

const List = styled.ul`
    list-style-type: square;
    color: #E70865;
    padding-left: 0;
`;

const ListItem = (props) => (<li><div className="u-color-black u-mar-vt-20" {...props} /></li>);

const TwoColUl = styled.ul`
    list-style-type: square;
    color: #E70865;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: ${20/16}rem;
    padding-left: 0;
`;
const TwoColLi = styled.li`
    grid-column: 1;
    grid-row: 1;
    margin-left: 20px;
    &:nth-child(n + 7) {
        grid-column: 2;
    }
    &:nth-child(6n + 2) {
        grid-row: 2;
    }
    &:nth-child(6n + 3) {
        grid-row: 3;
    }
    &:nth-child(6n + 4) {
        grid-row: 4;
    }
    &:nth-child(6n + 5) {
        grid-row: 5;
    }
    &:nth-child(6n) {
        grid-row: 6;
    }
`;

const LongGridList = ({items = []}) => (
    <TwoColUl>
        {items.map((item, i) => (
            <TwoColLi key={i}>
                <div className="u-color-black">{item}</div>
            </TwoColLi>
        ))}
    </TwoColUl>
)

const ThirdPartyLogisticsSection = () => (
    <Section title="Third Party Logistics">
        <div className="u-mar-vt-80 u-mar-vt-160@60">
            <SectionIntro>
                <SectionIntro.Image src="/images/third-party-logistics.svg" width={492} height={413} alt="" />
                <Headline2 className="u-mar-vt-40">Get Your Therapy to Your Patients. Faster.</Headline2>
                <p className="b-body b-body--large">
                3PL gets your therapy to the right patients at the right place at the right time. In this rapidly changing market, you need the right 3PL solution to anticipate and evolve to address industry challenges. Learn more about 3PL solutions that can help you change more patient lives. 
                </p>
            </SectionIntro>
            <div className="o-article-content o-article-content--full">
                <div className=" u-mar-vt-80">
                    <Eyebrow className="u-color-blue">The Right Solution</Eyebrow>
                    <Headline3 serif className="u-mar-bottom-40">4 Keys to Efficient Third-Party Logistics</Headline3>
                    <IconList>
                        <IconList.Item>
                            <CircleIcon src="https://images.ctfassets.net/2in405srp47m/56NvbNGpK0Ic7itCW0SAAi/3add475d26ab8c6702a2bd8ff2af2154/trusted.svg" />
                            <p className="b-body">
                                <strong>Consult</strong>{" "}early and strategically to set you up for success
                            </p>
                        </IconList.Item>
                        <IconList.Item>
                            <CircleIcon src="https://images.ctfassets.net/2in405srp47m/3vNIEmrJjlWfVOq4b8XXi3/35df74a9df89a4085d94f2505782d761/money.svg" />
                            <p className="b-body">
                                <strong>Unlock</strong>{" "}hidden potential in your supply chain to deliver cost-savings and increase market share
                            </p>
                        </IconList.Item>
                        <IconList.Item>
                            <CircleIcon src="https://images.ctfassets.net/2in405srp47m/3b0qZiM0sWLyUtTwcM6MUl/158adef27d9db61d538a167c2d6cb6e8/settings.svg" />
                            <p className="b-body">
                                <strong>Track</strong>{" "}quality metrics that drive innovation and inform optimization 
                            </p>
                        </IconList.Item>
                        <IconList.Item>
                            <CircleIcon src="https://images.ctfassets.net/2in405srp47m/2WN5TW3IlFnQaTUa7bFueA/ec800381a5b12aafaa0b912b9be56ad2/growth.svg" />
                            <p className="b-body">
                                <strong>Scale</strong>{" "}up through a configurable model that grows with you and your portfolio of brands
                            </p>
                        </IconList.Item>
                    </IconList>
                </div>
                <div className=" u-mar-vt-80">
                    <EndToEndGrid>
                        <EndToEndGridImgWrap>
                            <Image src="/images/logistics-photo.jpg" width={534} height={527} alt="Partnership extending from the factory to the provider office" />
                        </EndToEndGridImgWrap>
                        <EndToEndGridContent>
                            <Headline4 className="u-mar-bottom-40">An end-to-end business support</Headline4>
                            <div>
                                <Accordion header="How your 3PL program helps you grow">
                                    <p className='b-body'>Your 3PL program manager should act as an extension of your team. The right program manager takes responsibility not only for communications but also for business development. That includes:</p>
                                    <List>
                                        <ListItem><strong>Managing your account,</strong> from onboarding to process improvements and relationship-building</ListItem>
                                        <ListItem><strong>Leading meetings,</strong> from weekly updates to quarterly business reviews </ListItem>
                                        <ListItem><strong>Consulting on your business</strong> and advocating for you </ListItem>
                                        <ListItem><strong>Creating strategic solutions</strong> to optimize your value stream </ListItem>
                                        <ListItem><strong>Interfacing with other contacts</strong> within your distributor’s organization </ListItem>
                                    </List>
                                </Accordion>
                                <Accordion header="Solutions for your therapy’s unique needs">
                                    <p className='b-body'>Your 3PL experts see you through kickoff, project management, go-live and post-implementation. Based on your therapy’s unique needs, look for the best available solutions for:</p>
                                    <LongGridList items={[
                                        "Title and non-title models",
                                        "Warehousing",
                                        "Inventory management",
                                        "Cold-chain management",
                                        "Quality and regulatory programs",
                                        "Order-to-cash",
                                        "Worldwide shipping",
                                        "Import and export support",
                                        "Track and trace",
                                        "Pick, pack and ship",
                                        "Sample distribution",
                                        "Repackaging and relabeling"
                                    ]} />
                                </Accordion>
                            </div>
                        </EndToEndGridContent>
                    </EndToEndGrid>
                </div>
            </div>

        </div>
    </Section>
);

export default ThirdPartyLogisticsSection;