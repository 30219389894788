import React, {useEffect} from 'react';
import styled from '@emotion/styled';
import SectionIntro from '../../components/SectionIntro';
import Section from '../../components/Section';
import Image from 'next/image';
import { PATIENT_AND_PROVIDER_SURVEYS_2020, REASONS_PATIENTS_DONT_MEDICATE, CLINICAL_THERAPEUTICS_2018 } from '../../data/sources';
import {Eyebrow, Headline2, Headline3, Headline4, Accordion, Footnotes} from '../../public/bundle';


const AdherenceIntro = styled.div`
    max-width: ${830/16}rem;
`;

const StatGrid = styled.div`
    display: grid;
    grid-template-rows: auto 1px auto;
    grid-template-columns: 0px 1fr 0px;
    gap: ${40/16}rem;
    max-width: ${920/16}rem;
    margin: 0 auto;
    align-items: center;
    &::before {
        content: "";
        grid-column: 1;
        grid-column-end: span 3;
        grid-row: 2;
        background: #707070;
        align-self: stretch;
        justify-self: stretch;
    }
    @media (min-width: 40em) {
        grid-template-rows: 0px 1fr 0px;
        grid-template-columns: 1fr 1px 1fr;
        &::before {
            grid-column: 2;
            grid-row: 1;
            grid-row-end: span 3;
        }
    }
`;

const StatGridCell = styled.div`
    &:nth-child(1) {
        grid-row: 3;
        grid-column: 2;
    }
    &:nth-child(2) {
        grid-row: 1;
        grid-column: 2;
    }
    @media (min-width: 40em) {
        &:nth-child(1) {
            grid-row: 2;
            grid-column: 1;
        }
        &:nth-child(2) {
            grid-row: 2;
            grid-column: 3;
        }
    }
`;

const StatGridGraph = styled.img`
    width: 100%;
    height: auto;
    max-width: ${188/16}rem;
    max-height: ${188/16}rem;
`;

const CareGiverGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    gap: ${40/16}rem;
    > * {
        grid-column: 1;
    }
    > :nth-child(1) {
        grid-row: 1;
        justify-self: center;
    }
    > :nth-child(2) {
        grid-row: 2;
    }
    @media (min-width: 50em) {
        grid-template-columns: 1.4fr 1fr;
        grid-template-rows: auto;
        > :nth-child(1) {
            grid-column: 2;
        }
        > :nth-child(2) {
            grid-row: 1;
            grid-column: 1;
        }
    }
`;

const SupportGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    gap: ${40/16}rem;
    > * {
        grid-column: 1;
    }
    > :nth-child(1) {
        grid-row: 1;
    }
    > :nth-child(2) {
        grid-row: 2;
    }
    @media (min-width: 50em) {
        grid-template-columns: 1.2fr 1fr;
        grid-template-rows: auto;
        > :nth-child(2) {
            grid-row: 1;
            grid-column: 2;
        }
    }
`;



const SquareList = styled.ul`
    list-style-type:square;
`;

const AffordabilitySection = ({addFootnote}) => {
    useEffect(() => {
        addFootnote(PATIENT_AND_PROVIDER_SURVEYS_2020);
        addFootnote(CLINICAL_THERAPEUTICS_2018);
        addFootnote(REASONS_PATIENTS_DONT_MEDICATE);
    }, []);
    return (
        <Section title="Stay on Therapy">
            <div className="u-mar-vt-80 u-mar-vt-160@60">

                <SectionIntro>
                    <SectionIntro.Image src="/images/time-to-therapy.svg" width={460} height={430} alt="" />
                    <Headline2 className="u-mar-vt-40">Help Your Patients Stay on Therapy</Headline2>
                    <p className="b-body b-body--large">
                        It may be difficult for patients to adhere to an expensive specialty medication regimen.
                    </p>
                </SectionIntro>

                <div className="o-article-content o-article-content--full">
                    <div className=" u-mar-vt-40 u-mar-vt-80@60">
                        <AdherenceIntro>
                            <Eyebrow className="u-color-blue">Adherence</Eyebrow>
                            <Headline3 serif className="u-mar-bottom-40">Invest in better outcomes</Headline3>
                            <p className="b-body">
                                Empower patients to take ownership of their treatment journey by partnering with a cohesive, coordinated team that provides continued coaching and support to guide patients through their treatment journey.
                            </p>
                        </AdherenceIntro>
                    </div>
                    <div className="u-mar-vt-40 u-mar-vt-80@60">
                        <StatGrid>
                            <StatGridCell>
                                <p className="b-body">
                                A study of nearly 11,000 patients on a specialty medication found a 72 percent decrease in risk for therapy discontinuation and a higher probability of therapy adherence for patients enrolled in a support program, relative to unsupported patients.<Footnotes.Reference {...CLINICAL_THERAPEUTICS_2018} />
                                </p>
                                <div className="b-body">
                                    <strong>Top 3 reasons for nonadherence</strong><Footnotes.Reference {...REASONS_PATIENTS_DONT_MEDICATE} />
                                    <ol>
                                        <li>Side effects</li>
                                        <li>Perceived lack of efficacy</li>
                                        <li>Cost of medication</li>
                                    </ol>
                                </div>
                            </StatGridCell>
                            <StatGridCell>
                                <div className="b-body u-txt-center">
                                    <StatGridGraph src="/images/30.svg" alt="" />
                                    <p className="u-fw-medium">About half of specialty patients surveyed said they have missed a dose of their medication at some point.<Footnotes.Reference {...PATIENT_AND_PROVIDER_SURVEYS_2020} /></p>
                                </div>
                            </StatGridCell>
                        </StatGrid>
                    </div>
                    <div className=" u-mar-vt-40 u-mar-vt-80@60">
                        <CareGiverGrid>
                            <div>
                                <Image src="/images/additional-support-photo.jpg"  width={500} height={674} alt="Patient on the phone recieving support." />
                            </div>
                            <div>
                                <div className="u-mar-bottom-80">
                                    <Headline4>Ways to support patients and caregivers</Headline4>
                                    <p className="b-body">
                                    Specialized patient support services can bridge barrier gaps to improve patient adherence through automated and expedited processes and improved education.
                                    </p>
                                </div>
                                <div>
                                    <Headline4>Clinical care teams are key to patients’ success</Headline4>
                                    <p className="b-body b-body--large">
                                    Our care teams involving clinical experts and nurse case managers:
                                    </p>
                                    <SquareList className="b-body">
                                        <li className="u-color-magenta u-mar-bottom-20">
                                            <span className="u-color-black">
                                                Have specialized therapeutic knowledge
                                            </span>
                                        </li>
                                        <li className="u-color-magenta u-mar-bottom-20">
                                            <span className="u-color-black">
                                                Explain treatments to help patients understand when onboarding them to therapy
                                            </span>
                                        </li>
                                        <li className="u-color-magenta u-mar-bottom-20">
                                            <span className="u-color-black">
                                                Make house visits to help patients keep up with their treatment regimens at home
                                            </span>
                                        </li>
                                        <li className="u-color-magenta u-mar-bottom-20">
                                            <span className="u-color-black">
                                                Offer hands-on training in home injection protocols
                                            </span>
                                        </li>
                                        <li className="u-color-magenta u-mar-bottom-20">
                                            <span className="u-color-black">
                                                Are highly trained in behavioral coaching and motivational interviewing
                                            </span>
                                        </li>
                                        <li className="u-color-magenta u-mar-bottom-20">
                                            <span className="u-color-black">
                                                Talk to patients regularly via phone and video chat
                                            </span>
                                        </li>
                                        <li className="u-color-magenta u-mar-bottom-20">
                                            <span className="u-color-black">
                                                Provide continual adherence support to patients on an individual basis
                                            </span>
                                        </li>
                                    </SquareList>
                                </div>
                            </div>

                        </CareGiverGrid>
                    </div>
                    <div className=" u-mar-vt-40 u-mar-vt-80@60">
                        <SupportGrid>
                            <div>
                                <Headline4>How to support providers and their teams</Headline4>
                                <p className="b-body u-mar-bottom-80">
                                    Healthcare providers are the first people patients turn to when they have questions about their treatment. For patients to access and stay on the right therapy, providers must be familiar with all their options.
                                </p>
                                <Headline4>Provider engagement and education can benefit patients</Headline4>
                                <p className="b-body">
                                    It’s crucial prescribers are engaged and educated when a new therapy comes to market or earns a second indication so providers can support patients on that therapy.  
                                </p>
                            </div>
                            <div>
                                <Accordion header="Sales Enhancement Services">
                                    <p className="b-body">
                                        Our sales staff teams help providers learn about new therapies and therapy indications. Available as needed, they can maximize your sales reach, adding critical enhancement to on-the-ground teams.
                                    </p>
                                </Accordion>
                                <Accordion header="Field Reimbursement Managers">
                                    <p className="b-body">
                                    Reduce patient barriers to therapy by guiding prescribing physicians through reimbursement challenges.
                                    </p>
                                </Accordion>
                                <Accordion header="Nurse Educators">
                                    <p className="b-body">
                                        Highly trained, disease-specific, expert nurses give providers the best insight into new therapies and therapy management for specific regimens, sharing the most up-to-date information in their field.
                                    </p>
                                </Accordion>
                            </div>
                        </SupportGrid>
                    </div>
                </div>

            </div>
        </Section>
    )
};

export default AffordabilitySection;