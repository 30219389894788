import React, {useContext, useState, useEffect} from 'react';
import styled from '@emotion/styled';
import {Textbox, Button, Headline4} from '../public/bundle.js';
import { serializeFormData, submitSalesforceForm } from '../utilities/forms.js';

const FormGrid = styled.div`
    display: grid;
    grid-template-rows: auto ${40/16}rem auto;
    grid-template-columns: 1fr 1fr 1fr;
`;

const FormContainer = styled.div`
    max-width: 587px;
    grid-column: 1;
    grid-column-end: span 3;
    grid-row: 1;
    grid-row-end: span 2;
    justify-self: center;
    background: #ffffff;
    box-shadow: 0 0 5px #000;
    padding: ${40/16}rem;
    z-index: 2;
`;
const Form = styled.form`
    display:grid;
    grid-template-rows: auto auto auto auto auto;
    grid-template-columns: 1fr 1fr;
    gap: ${40/16}rem;
`;

const FormTitleCell = styled.div`
    grid-row: 1;
    grid-column: 1;
    grid-column-end: span 2;
`;
const FormEmailCell = styled.div`
    grid-row: 2;
    grid-column: 1;
    grid-column-end: span 2;
`;
const FormCompanyCell = styled.div`
    grid-row: 3;
    grid-column: 1;
`;
const FormDiseaseCell = styled.div`
    grid-row: 3;
    grid-column: 2;
`;
const FormTherapyCell = styled.div`
    grid-row: 4;
    grid-column: 1;
    grid-column-end: span 2;
`;
const FormButtonCell = styled.div`
    grid-row: 5;
    grid-column: 1;
    grid-column-end: span 2;
    justify-self: center;
`;

const FooterImage = styled.img`
    max-width: ${props => props.width/16}rem;
    max-height: ${props => props.height/16}rem;
    width: 100%;
    height: auto;
`;


const ImageCell = styled.div`
    grid-row: 2;
    grid-row-end: span 2;
    align-self: flex-end;
    &:nth-child(2) {
        grid-column: 1;
        justify-self: left;
    }
    &:nth-child(3) {
        grid-column: 2;
        justify-self: center;
    }
    &:nth-child(4) {
        grid-column: 3;
        justify-self: right;
    }
`;

const OutreachFormContext = React.createContext({
    hasFormSent: false,
    setHasFormSent: () => {}
});

const OutreachForm = () => {
    const {setHasFormSent} = useContext(OutreachFormContext);

    const [emailValue, setEmailValue] = useState('');

    const handleEmailChange = (event) => {
        setEmailValue(event?.target?.value);
    }

    const [hasError, setHasError] = useState(false);
    const productionCampaignId = '7015b000005B7uPAAS';
    const uatCampaignId = '7015b000005B7uPAAS';
    const submitterType = 'Marketing';
    const ownerInfo = '0050a00000LUzbtAAD';
    const formId = 'CommCart Connect';
  
    const campaign = process.env.NODE_ENV === 'production' ? productionCampaignId : uatCampaignId;
    
  

    const handleSubmit = (e) => {
      
        e.preventDefault();
        const formUrl = window.location.href;
        const formData = {
          campaign,
          formUrl,
          submitterType,
          ownerInfo,
          ...serializeFormData(new FormData(e.target))
        }
    
        submitSalesforceForm(formId, formData).then(data => {
          
          const {isSuccess} = data;
          if (isSuccess) {
            setHasFormSent(true);
          } else {
            setHasError(true);
          }
  
        }).catch((err) => {
          setHasError(true)
        });
  
    }
    
    useEffect(() => {
        if (localStorage.getItem('email')) {
            setEmailValue(localStorage.getItem('email'));
        }
    }, []);



    return (
        <div className="o-article-content o-article-content--full u-pad-vt-120">
            <FormGrid>
                <FormContainer>
                    <Form className={hasError ? "u-disp-none" : ''} onSubmit={handleSubmit}>
                        <FormTitleCell>
                            <Headline4>Let us know where to reach you</Headline4>
                        </FormTitleCell>
                        <FormEmailCell>
                            <Textbox label="Email" name="email" value={emailValue} onChange={handleEmailChange} />
                        </FormEmailCell>
                        <FormCompanyCell>
                            <Textbox label="Company" name="company" />
                        </FormCompanyCell>
                        <FormDiseaseCell>
                            <Textbox label="Disease State" name="DiseaseState" />
                        </FormDiseaseCell>
                        <FormTherapyCell>
                            <Textbox label="Therapy Launch Date" name="therapyLaunchdate">
                                Please format as MM/DD/YYYY
                            </Textbox>
                        </FormTherapyCell>
                        <FormButtonCell>
                            <Button>Submit</Button> 
                        </FormButtonCell>
                    </Form>
                    <p className={hasError ? "b-body" : 'u-disp-none'}>
                        There was an error submitting the form. Please try refreshing the page or check back later.
                    </p>
                </FormContainer>
                <ImageCell>
                    <FooterImage src="/images/footer-house.svg" width={383} height={270} />
                </ImageCell>
                <ImageCell>
                    <FooterImage src="/images/footer-pin.svg" width={229} height={132} />
                </ImageCell>
                <ImageCell>
                    <FooterImage src="/images/footer-hospital.svg" width={382} height={268} />
                </ImageCell>
            </FormGrid>
        </div>
    )
}

OutreachForm.Context = OutreachFormContext;

export default OutreachForm;