import React, {useContext} from 'react';

export const defaultSectionNavState = {
    sectionList: [],
    showSectionNavList: false
}

export const SectionNavContext = React.createContext({
    ...defaultSectionNavState,
    addSection: () => {},
})

export const sectionNavReducer = (state = defaultSectionNavState, action) => {
    
    const actionTypeResolvers = {};
    const updateState = (updatedObj = {}) => Object.assign({}, state, updatedObj);
    actionTypeResolvers['add'] = () => {
        
        const newSectionList = [...state.sectionList, action.payload];
        
        const isNew = newSectionList.findIndex(s => s.sectionId === action.payload.sectionId) === newSectionList.length - 1;
        
        const returnedSectionList = isNew ? newSectionList : state.sectionList.map(s => s.sectionId === action.payload.sectionId ? action.payload : s);

        return isNew ? updateState({sectionList: returnedSectionList}) : updateState({sectionList: returnedSectionList});
    }

    return actionTypeResolvers[action.type] ? actionTypeResolvers[action.type]() : state;

}



export const SectionNavLink = ({currentSection, sectionId, title}) => {
    
    const isCurrent = currentSection && currentSection.sectionId === sectionId;
    const handleClick = (event) => {
        event.preventDefault();
        const sectionElm = document.getElementById(sectionId);
        sectionElm.scrollIntoView({behavior: 'smooth'});
        sectionElm.focus({
            preventScroll: true
        });
    }

    const linkClass = `c-section-link-list__link ${isCurrent ? 'c-section-link-list__link--current' : ''}`;

    return (
        <a className={linkClass} href={`#${sectionId}`} onClick={handleClick}>
            {title}
        </a>
    )
}

export const SectionNavLinkList = () => {
    
    const {sectionList, showSectionNavList} = useContext(SectionNavContext);
    const currentSection = [...sectionList].reverse().find(s => s.isInView);
    const listClass = `b-bare c-section-link-list ${currentSection ? '' : 'c-section-link-list--hidden'}`;

    if (!showSectionNavList) return null;

    return (
        <nav>
            <ol className={listClass}> 
                {sectionList.map((section, index) => (
                    <li className="b-bare u-mar-vt-10" key={index}>
                        <SectionNavLink {...section} currentSection={currentSection} />
                    </li>
                ))}
            </ol>
        </nav>
    );
}