import React, {useEffect} from 'react';
import styled from '@emotion/styled';
import SectionIntro from '../../components/SectionIntro';
import CircleIcon from '../../components/CircleIcon';
import HighlightEm from '../../components/HighlightEm';
import PaddedBox from '../../components/PaddedBox';
import Image from 'next/image';
import Section from '../../components/Section';
import {Eyebrow, Headline2, Headline3, Headline4, Accordion, Footnotes} from '../../public/bundle';
import { PATIENT_SURVEY_2020, PRIOR_AUTH_SURVEY_2018, DATA_ON_FILE_2021 } from '../../data/sources';

const StatGrid = styled.div`
    display: grid;
    grid-template-rows: 0px 1fr 0px;
    grid-template-columns: 1fr 1px 1fr;
    gap: ${40/16}rem;
    max-width: ${860/16}rem;
    margin: 0 auto;
    align-items: center;
`;

const StatGridStat = styled.div`
    grid-column: 1;
    grid-row: 2;
`;

const StatGridDesc = styled.div`
    grid-column: 3;
    grid-row: 2;
`;

const StatGridLine = styled.div`
    grid-column: 2;
    grid-row: 1;
    grid-row-end: span 3;
    background: #707070;
    align-self: stretch;
`;

const EpaGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto ${122/16}rem auto;
    gap: ${40/16}rem;
    align-items: center;
    @media (min-width: 40em) {
        grid-template-columns: 1fr ${122/16}rem 1fr;
        grid-template-rows: auto auto;
    }
`;

const EpaGridTitle = styled.div`
    grid-column: 1;
    grid-row: 1;
    @media (min-width: 40em) {
        grid-column-end: span 3;
    }
    `;

const EpaGridTextBlock = styled.div`
    grid-column: 1;
    grid-row: 2;
`;

const EpaGridList = styled.ul`
    grid-column: 1;
    grid-row: 4;
    list-style-type: square;
    list-style-position: outside;
    margin: 0;
    @media (min-width: 40em) {
        grid-column: 3;
        grid-row: 2;
    }
    `;

const EpaGridIconContainer = styled.div`
    grid-column: 1;
    grid-row: 3;
    justify-self: center;
    @media (min-width: 40em) {
        grid-column: 2;
        grid-row: 2;
    }
`;

const PatientJourneyGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    gap: ${40/16}rem;
    @media (min-width: 50em) {
        grid-template-columns: 1fr .35fr .35fr;
        grid-template-rows: auto auto;
    }
`;

const PatientJourneyTitle = styled.div`
    grid-column: 1;
    grid-row: 1;
    @media (min-width: 50em) {
        grid-column-end: span 2;
    }
`;

const PatientJourneyContentWrap = styled.div`
    grid-column: 1;
    grid-row: 3;
    @media (min-width: 50em) {
        grid-row: 2;
    }
`;

const PatientJourneyImage = styled.div`
    align-self: flex-start;
    justify-self: center;
    grid-row: 2;
    grid-column: 1;
    @media (min-width: 50em) {
        grid-column: 2;
        grid-column-end: span 2;
    }
`;

const AccessSection = ({addFootnote}) => {
    useEffect(() => {
        addFootnote(PATIENT_SURVEY_2020);
        addFootnote(PRIOR_AUTH_SURVEY_2018);
        addFootnote(DATA_ON_FILE_2021);
    }, []);
    return (
        <Section title="Access Barriers">
        <div className="u-mar-vt-80 u-mar-vt-160@60">
            <SectionIntro>
                <SectionIntro.Image src="/images/medication-access.svg" width={450} height={350} alt="" />
                <Headline2 className="u-mar-vt-40">Beat Access Barriers. Improve Speed to Therapy.</Headline2>
                <p className="b-body b-body--large">
                Patients need a simpler process for getting on therapy. Technology can achieve that. We connect payers with providers to reduce wait time, so patients can get onboarded to therapy faster.
                </p>
            </SectionIntro>
            <div className="u-mar-vt-80">
                <StatGrid>
                    <StatGridStat>
                        <Headline4 Elm="div">
                            <HighlightEm>1 in 4</HighlightEm> patients experience delays in getting medications due to prior authorization.<Footnotes.Reference {...PATIENT_SURVEY_2020} />
                        </Headline4>
                    </StatGridStat>
                    <StatGridDesc>
                        <p className="b-body">
                        Patients face more challenges than ever before. The rise in prior authorization (PA) to control spending has placed an additional burden on patients due to therapy delays and denials.
                        </p>
                    </StatGridDesc>
                    <StatGridLine />
                </StatGrid>
            </div>

            <div className="o-article-content o-article-content--full">
                <div className="u-mar-vt-80">
                    <EpaGrid>
                        <EpaGridTitle>
                            <Eyebrow className="u-color-blue">Helping Patients</Eyebrow>
                            <Headline3 serif>Our technology puts therapies in patients’ hands faster</Headline3>
                        </EpaGridTitle>
                        <EpaGridTextBlock>
                            <p className="b-body u-mar-vt-0">
                            CoverMyMeds’ solutions address these access challenges, reducing time to therapy by facilitating PA requests, denials and overturns between payers and providers. As a leader in electronic PA (ePA) support, CoverMyMeds automates the PA process, creating efficiencies and offering providers PA support.
                            </p>
                        </EpaGridTextBlock>
                        <EpaGridIconContainer>
                            <CircleIcon src="https://images.ctfassets.net/2in405srp47m/1iIor7W1nSyWCKkhPKI6cu/aed27d8a7d513f85c9e10d5afb235563/epa.svg" alt="" />
                        </EpaGridIconContainer>
                        <EpaGridList>
                            <li className="b-body u-color-magenta">
                                <span className="u-color-black">
                                    With our proprietary ePA technology, more PA requests make it to the health plan for determination.
                                </span>
                            </li>
                            <li className="b-body u-color-magenta u-mar-top-20">
                                <span className="u-color-black">
                                    Some patients could leave the pharmacy with their medication while PA requests await approval thanks to our technology and pharmacy network.
                                </span>
                            </li>
                        </EpaGridList>
                    </EpaGrid>
                </div>
                <div className=" u-mar-vt-80">
                    <div className="u-bgc-orange-tint">
                        <PaddedBox>
                            <Eyebrow className="u-fw-light u-mar-bottom-40">Patient Impact</Eyebrow>
                            <Headline2 Elm="p" serif className="u-mar-vt-0">
                                <HighlightEm>91%</HighlightEm>{" "}
                                of providers reported that the traditional 
                                PA process results in delayed access to necessary care, while{" "}
                                <HighlightEm>75%</HighlightEm>{" "}
                                reported the traditional PA process has led
                                to patients abandoning treatment altogether.<Footnotes.Reference {...PRIOR_AUTH_SURVEY_2018} />
                            </Headline2>
                        </PaddedBox>
                    </div>
                </div>
                <div className=" u-mar-vt-80">
                    <PatientJourneyGrid>
                        <PatientJourneyTitle>
                            <Eyebrow className="u-color-blue">Spanning the patient journey</Eyebrow>
                            <Headline3 serif>Carrying your brand — and your patients — through the access journey</Headline3>
                        </PatientJourneyTitle>
                        <PatientJourneyImage>
                            <Image src="/images/access-patient-journey.jpg" alt="Patient seen at home relaxing and at clinic with provider staff" width={484} height={478} />
                        </PatientJourneyImage>
                        <PatientJourneyContentWrap>
                            <p className="b-body">
                            Just like patients, therapies vary widely. Some are novel or niche solutions and require highly complex solutions. Others are broad-reaching and require high-volume capabilities. Depending on your therapy’s specifications, a range of patient support solutions may be necessary.  
                            </p>
                            <p className="b-body">
                            Once patients get on therapy, it’s critical they receive support to stay on their therapy and care plan as prescribed by their provider. This support must be designed to fit your brand and must connect access, affordability and adherence solutions to get patients on therapy as quickly as possible and keep them on therapy as long as medically necessary.  
                            </p>

                            <div className="u-mar-top-80">
                                <Headline4>Two options for connecting with the healthcare ecosystem:</Headline4>
                                <Accordion header="Tech-driven patient support">
                                    <p className="b-body">
                                        Our single-platform, tech-driven hub connects all stakeholders for the first time and enables providers with patient-support services and programs. This end-to-end solution creates visibility for providers across the entire patient journey and transforms how patients access, afford and adhere to complex therapies. 
                                    </p>
                                </Accordion>
                                <Accordion header="Robust support for high-touch brands">
                                    <p className="b-body">
                                        Our robust hub solutions support highly complex or niche therapies and patient populations requiring access and reimbursement support. 
                                    </p>
                                </Accordion>
                            </div>

                        </PatientJourneyContentWrap>
                    </PatientJourneyGrid>
                </div>
                <div className=" u-mar-vt-80">
                    <PaddedBox border>
                        <div>
                            <Eyebrow className="u-fw-light u-mar-bottom-40">Patient Impact</Eyebrow>
                            <Headline2 Elm="p" serif className="u-mar-vt-0">
                                <HighlightEm>30+</HighlightEm>{" "}diverse programs representing nearly <HighlightEm>80</HighlightEm>{" "}brands are supported by our robust hub solutions.<Footnotes.Reference {...DATA_ON_FILE_2021} />
                            </Headline2>
                        </div>
                    </PaddedBox>
                </div>
            </div>

        </div>
        </Section>
    )
};

export default AccessSection;