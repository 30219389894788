import styled from '@emotion/styled';

const IconList = styled.ul`
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    gap: ${40/16}rem;
    margin: 0;
    padding: 0;
    @media (min-width: 50em) {
        grid-template-rows: auto;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

`;

const IconListItem = styled.li`
    list-style: none;
    margin: 0;
    padding: 0;
    grid-row: 1;
    
    &:nth-child(1) {
        grid-column: 1;
    }
    &:nth-child(2) {
        grid-column: 2;
    }

    &:nth-child(3) {
        grid-column: 1;
        grid-row: 2;
    }
    &:nth-child(4) {
        grid-column: 2;
        grid-row: 2;
    }
    @media (min-width: 50em) {

        &:nth-child(3) {
            grid-column: 3;
            grid-row: 1;
        }
        &:nth-child(4) {
            grid-column: 4;
            grid-row: 1;
        }
    }
`;

IconList.Item = IconListItem;

export default IconList;