import React, {useState, useReducer} from 'react';
import {apolloClient} from '../../apollo/client.js';
import gql from 'graphql-tag';
import Head from 'next/head'
import btoa from 'btoa';
import atob from 'atob';
import HeaderSection from '../../sections/header';
import ThirdPartySection from '../../sections/third-party-logistics';
import AccessSection from '../../sections/access';
import AffordabilitySection from '../../sections/affordability';
import AdditionalSupportSection from '../../sections/additional-support';
import FooterSection from '../../sections/footer';
import DigDeeper from '../../sections/dig-deeper';
import OutreachForm from '../../components/OutreachForm';
import ThankYouSection from '../../sections/thank-you';
import {Footnotes} from '../../public/bundle.js';

import { SectionNavContext, SectionNavLinkList, defaultSectionNavState, sectionNavReducer } from '../../components/SectionNav';

const page_ID = "75ptN3qChPwafMjdDOkTbo";

const RenderIf = ({conditional, children}) => {
    
    if (!conditional) return null;
    
    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    )
}

const footnoteListReducer = (state = [], action) => {
    const typeResolver = {};
    typeResolver['add'] = () => {
        const {payload} = action;
        const isNew = state.findIndex((s) => s.title === payload.title && s.href === payload.href) < 0;
        if (isNew) return [...state, payload];
        return state;
    }
    return typeResolver[action?.type] ? typeResolver[action?.type]() : state;
}

export default function ResultsPage({additionalSupport, affordabilityOptions, otherChallenges, thirdPartySelected, pageData, pageUrl}) {
    
    const pageTitle = pageData?.title;
    const metaImage = pageData?.socialImage?.url;
    const metaDescription = pageData?.socialDescription;
  
    const [hasFormSent, setHasFormSent] = useState(false);

    const [sectionState, dispatchNavSections] = useReducer(sectionNavReducer, Object.assign({}, defaultSectionNavState, {showSectionNavList: true}));
    const addSection = (payload) => dispatchNavSections({type: 'add', payload});
    
    const [footnoteList, dispatchFootnoteList] = useReducer(footnoteListReducer, []);
    const addFootnote = (payload) => dispatchFootnoteList({type: 'add', payload});

    const hasConditionalSupport = additionalSupport === "Yes";
    const hasAffordabilityOptions = affordabilityOptions === "Yes";
    const hasOtherChallenges = otherChallenges === "Yes";
    const hasThirdPartySelected = thirdPartySelected === "No";
    const hasSomeScreens = hasConditionalSupport || hasAffordabilityOptions || hasOtherChallenges || hasThirdPartySelected;

    return (
        <SectionNavContext.Provider value={{...sectionState, addSection}}>
            <Head>
                <title>{pageTitle} | CoverMyMeds</title>
                <meta name="description" key="description" content={metaDescription} />

                <meta property="og:url" key="og:url" content={pageUrl} />

                <meta property="og:title" key="og:title" content={pageTitle} />
                <meta property="og:description" key="og:description" content={metaDescription} />
                <meta property="og:image" key="og:image" content={metaImage} />

                <meta name="twitter:title" key="twitter:title" content={pageTitle} />
                <meta name="twitter:description" key="twitter:description" content={metaDescription} />
                <meta name="twitter:image" key="twitter:image" content={metaImage} />
            </Head>
            <OutreachForm.Context.Provider value={{hasFormSent, setHasFormSent}}>
                <RenderIf conditional={hasFormSent}>
                    <ThankYouSection />
                </RenderIf>
                <RenderIf conditional={!hasFormSent}>
                    <Footnotes footnoteList={footnoteList}>
                        <SectionNavLinkList />
                        <div className="o-edge">
                            <RenderIf conditional={!hasSomeScreens}>
                                <DigDeeper />
                            </RenderIf>
                            <RenderIf conditional={hasSomeScreens}>
                                <HeaderSection />
                            </RenderIf>
                            <RenderIf conditional={hasThirdPartySelected}>
                                <ThirdPartySection addFootnote={addFootnote} />
                            </RenderIf>            
                            <RenderIf conditional={hasOtherChallenges}>
                                <AccessSection addFootnote={addFootnote} />
                            </RenderIf>
                            <RenderIf conditional={hasAffordabilityOptions}>
                                <AffordabilitySection addFootnote={addFootnote} />
                            </RenderIf>
                            <RenderIf conditional={hasConditionalSupport}>
                                <AdditionalSupportSection addFootnote={addFootnote} />
                            </RenderIf>
                            <aside className="o-article-content o-article-content--full u-mar-vt-40">
                                <Footnotes.List />
                            </aside>
                        </div>
                        <RenderIf conditional={hasSomeScreens}>
                            <FooterSection />
                        </RenderIf>
                    </Footnotes>
                </RenderIf>
            </OutreachForm.Context.Provider>
        </SectionNavContext.Provider>
    )
}

const opts = ["Yes", "No"];

const getOptionPath = (a, b, c, d) => (
    {
        params: {
            response: btoa(`["${opts[a]}","${opts[b]}","${opts[c]}","${opts[d]}"]`)
        }
    }
);


export const getStaticPaths = async () => {
    return {
      paths: [
        getOptionPath(0, 0, 0, 0),
        getOptionPath(1, 0, 0, 0),
        getOptionPath(0, 1, 0, 0),
        getOptionPath(0, 0, 1, 0),
        getOptionPath(0, 0, 0, 1),
        getOptionPath(1, 1, 0, 0),
        getOptionPath(1, 0, 1, 0),
        getOptionPath(1, 0, 0, 1),
        getOptionPath(0, 1, 1, 0),
        getOptionPath(0, 1, 0, 1),
        getOptionPath(0, 0, 1, 1),
        getOptionPath(1, 1, 1, 0),
        getOptionPath(1, 1, 0, 1),
        getOptionPath(1, 0, 1, 1),
        getOptionPath(0, 1, 1, 1),
        getOptionPath(1, 1, 1, 1)
      ],
      fallback: true,
    }
  }
  

export const getStaticProps = async ({params}) => {

    const [additionalSupport, affordabilityOptions, otherChallenges, thirdPartySelected] = JSON.parse(atob(params.response));

    const { data } = await apolloClient.query({
        query: gql`
          query GetHomePageData {
            pageData: pages(id: "${page_ID}" preview: ${process.env.CONTENTFUL_PREVIEW}) {
              sys { id }
              title
              socialDescription
              socialImage {
                  url
              }
            }
          }
        `
      });
    
      const pageData = data?.pageData || {};
      const pageUrl = `${process.env.ABSOLUTE_URL}/${params.response}`;

    return {
        props: {additionalSupport, affordabilityOptions, otherChallenges, thirdPartySelected, pageData, pageUrl}, revalidate: 60
    };
}
