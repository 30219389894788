import React from 'react';
import styled from '@emotion/styled';
import OutreachForm from '../../components/OutreachForm';
import SectionIntro from '../../components/SectionIntro';
import Section from '../../components/Section';
import {Eyebrow, Headline2, Headline3, Headline4, Accordion} from '../../public/bundle';

const FooterSection = () => (
    <Section Elm="footer" title="Conclusion">
        <div className="u-bgc-orange-tint">
            <div className="o-edge">
                <div className="o-article-content o-article-content--full u-pad-vt-100">
                    <SectionIntro>
                        <Headline2 className="u-mar-vt-40">Better Outcomes Are Achievable Together</Headline2>
                        <p className="b-body b-body--large">
                            Patients face many roadblocks along their treatment journeys. But the right interventions at the right time can keep them on course — and on the path to a healthier life. Your therapies change patient lives for the better when they perform at their highest in the market.
                        </p>
                    </SectionIntro>
                    <div className="u-mar-top-80">
                        <OutreachForm />
                    </div>
                    
                </div>
            </div>
        </div>
    </Section>
);

export default FooterSection;