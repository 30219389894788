import React from 'react';
import styled from '@emotion/styled';
import OutreachForm from '../../components/OutreachForm';
import SectionIntro from '../../components/SectionIntro';
import {Eyebrow, Headline2, Headline3, Headline4, Accordion} from '../../public/bundle';

const DigDeeper = () => (
    <div className="o-article-content o-article-content--full u-pad-top-100">
        <SectionIntro>
            <Headline2 Elm="h1" className="u-mar-vt-40">Let’s Dig Deeper</Headline2>
            <p className="b-body b-body--large">
                 Based on your responses, we need more information to better understand how we can help. We’ll be in touch soon to learn more about your goals and how our end-to-end solutions can help.  
            </p>
        </SectionIntro>
        <div className="u-mar-top-80">
            <OutreachForm />
        </div>
    </div>
);

export default DigDeeper;