import styled from '@emotion/styled';

const PaddedBox = styled.div`
    padding: 12.5vw 15vw;
    box-shadow: ${props => props.border && `inset 0 0 0 ${20/16}rem #FFF2EB`};
    @media (min-width: 75em) {
        padding: ${150/16}rem ${180/16}rem;
    }
`;

export default PaddedBox;